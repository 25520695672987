import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
/* --- import Components ----*/
import Layout from 'src/components/Layout';
import { Section } from '../../components/Section';
import { RecruitersSection } from 'src/components/Partners';
import { HeroHomeWrapper } from 'src/components/Hero/elements';
import HeroText from 'src/components/LandingPage/HeroText';
import { MainVideo } from 'src/components/Projects';
import { CTASection } from 'src/components/CTA';
import QALandingPage from 'src/components/QuestionsAnswer/QALandingPage';
import ApplyFormLanding from 'src/components/ApplicationForm/landingPage/ApplyFormLanding';
import TestimonialsSectionLandingPage from 'src/components/LandingPage/TestimonialsSectionLandingPage';
import ButtonScrollToForm from 'src/components/LandingPage/ButtonScrollToForm';
import ProgramLandingPage from 'src/components/LandingPage/Program/ProgramLandingPage';
import FinancingLandingPage from 'src/components/LandingPage/FinancingLandingPage';
import FeaturesSection from 'src/components/Bootcamp/FeaturesSection';
/* --- import Utils ----*/
import { SessionDateDisplay, nextSessionsFilter } from 'src/utils/helpers';

const ELearningLanding = ({ location, pageContext: { nextSessions } }) => {
  // image display
  const imageData = useStaticQuery(graphql`
    query {
      image1: file(relativePath: { eq: "2023_students.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 350, placeholder: NONE)
        }
      }
      image2: file(relativePath: { eq: "2023_students4.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 350, placeholder: NONE)
        }
      }
      image3: file(relativePath: { eq: "2023_students11.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 350, placeholder: NONE)
        }
      }
      video: file(relativePath: { eq: "2023_studentsAndTeacher3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  // next session
  const nextELearningSession = nextSessionsFilter(
    nextSessions,
    'formation-developpeur-web-et-web-mobile'
  );
  const date = SessionDateDisplay(nextELearningSession[0], 'E-Learning');

  return (
    <Layout
      pathname={location.pathname}
      businessType="B2C"
      pageName="Formation en e-learning + mentoring"
      pageType="landingPage"
      metaTitle="Le Reacteur | Apprendre à coder - Formation Développeur Web"
      metaDescription="Apprenez à coder en rejoignant Le Reacteur, une formation en e-learning avec un accompagnement personnalisé, pendant 6 mois."
      css={{ paddingTop: 35 }}
    >
      {/* FIRST BANNER => form */}
      <Section theme="primary" hero landing>
        <HeroHomeWrapper landing>
          <HeroText fromPage="rncp5-e-learning" />
          <ApplyFormLanding
            data={{
              bootcampType: 'E-Learning',
              certification: 'RNCP5',
              nextSessions: nextELearningSession,
            }}
          />
        </HeroHomeWrapper>
      </Section>
      {/* SECOND BANNER => video */}
      <Section landing>
        <MainVideo
          mainThumbnail={imageData.video.childImageSharp.fluid}
          link="https://www.youtube.com/embed/59XH_-eMQhE"
          loopRef="59XH_-eMQhE"
          noModal
        />
        <ButtonScrollToForm text={`Je postule\u00A0!`} />
      </Section>
      {/* THIRD BANNER => Testimonials */}
      <TestimonialsSectionLandingPage />
      {/* FOURTH BANNER => Objectives */}
      <ProgramLandingPage fromPage="rncp5-e-learning" />
      {/* FIFTH BANNER => Picto */}
      <FeaturesSection
        fromPage="rncp5-e-learning"
        landing
        images={[
          imageData.image1.childImageSharp.gatsbyImageData,
          imageData.image2.childImageSharp.gatsbyImageData,
          imageData.image3.childImageSharp.gatsbyImageData,
        ]}
      />
      {/* SIXTH BANNER => Financements */}
      <FinancingLandingPage />
      {/* SEVENTH BANNER => Companies */}
      <RecruitersSection theme="secondary" landing />
      {/* EIGTH BANNER => Ready to launch  */}
      <CTASection
        onBoardingPromise="3 à 6 mois de code"
        course="Développeur Web"
        nextSession={date}
        format="en e-learning + mentoring"
        fromFormationsPageCertification="RNCP5"
        landing
      />
      {/* NINTH BANNER => FAQ  */}
      <QALandingPage fromPage="RNCP5-landing" />
    </Layout>
  );
};

export default ELearningLanding;
